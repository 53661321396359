
  .cndAgreement .instruction {
    border: 2px solid #F04DA1;
    width: min(600px, 90%);
    margin: 20px auto;
    padding: 40px;
    text-align: center;
 }
 .cndAgreement .proceedToC
{
   background-color: #F04DA1;
   color: white;
}
 
 .cndAgreement .instruction p {
    font-size: 30px;
 }
 
 .btn-rounded {
    border-radius: 25px;
    padding: 10px 18px;
    margin: 0 35px;
    border: 0;
    outline: 0;
    transition: 0.3s all ease;
 }
 
 .btn-rounded:hover {
    transform: scale(1.05);
 }
 
 
 /* /----------------------------------------------------------------------/ */
 .cndAgreementSign .contractAgreement {
    width: min(800px, 90%);
    margin: 20px auto;
    border: 2px solid #F04DA1;
    padding: 20px 10px;
 }
 
 .cndAgreementSign .agreement-text ul li {
    display: flex;
 }
 
 .cndAgreementSign .agreement-text ul li span:first-child {
    margin-right: 5px;
 }
 
 .cndAgreementSign :is(.btn-wrapper) .btn {
    min-width: 150px;
 }
 
 #cndagreementModal .btn {
    width: 150px;
 }

 #cndagreementModal .floating-label {
   background-color: white !important;
 }

 #cndagreementModal .modal-backdrop.show {
   opacity: 0 !important;
}

 #cndagreementModal .modal-content {
   border:2px solid #F04DA1;
}

 #cndagreementModal .floating-input{ 
   border:none;
}
 #cndagreementModal .floating-control { 
   /* border:2px solid #F04DA1; */
}

