/* candidate_interview css start*/
.candidate_interview .candidate_interview-photo {
  width: 200px;
  height: 200px;
}

.candidate_interview .candidate_interview-photo img {
  object-fit: cover;
  border: 3px solid #ccc;
  border-radius: 5px;
}

.candidate_interview-card {
  border: 1px solid var(--color-pri-red);
  text-align: center;
  border-radius: 11px;
  border-top-width: 10px;
  padding: 20px 0;
  font-size: 20px;
}

.profile-picture {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
}

.interview-list {
  /* width: 90%;*/
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.candidate_interview_container input[type="radio"]:checked + label {
  background-color: var(--color-pri-red);
  color: var(--color-white);
}

/* .candidate_interview_container .btn {
   border: 1px solid var(--color-pri-red);
}

.candidate_interview_container .btn:hover {
   color: white;
   background-color: var(--color-pri-red);
   border-color: var(--color-pri-red);
} */

.candidate_interview_container .interview-list tbody tr:is(:hover, .active) {
  box-shadow: inset 2px 2.1px 1px var(--color-pri-red),
    inset -2px -2px 1px var(--color-pri-red);
  -moz-box-shadow: inset 2px 3px 1px var(--color-pri-red),
    inset -2px -2px 1px var(--color-pri-red);
}

.candidate_interview_container .interview-list td {
  vertical-align: middle;
  word-wrap: break-word;
  white-space: normal;
}

.interview-list .icon {
  text-align: center;
}

.interview-list .icon img {
  width: 33px;
  cursor: pointer;
}

.interview-list .icon .text {
  display: block;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.3px;
  white-space: nowrap;
}

.interview-list .icon2 {
  color: rgb(39, 174, 96);
}

.interview-list .icon3 {
  color: rgb(214, 51, 132);
}

.interview-list .icon4 {
  color: #007bff;
}

.bg-gray {
  background-color: var(--color-grey);
}

.reschedule-popup {
  position: absolute;
  padding-bottom: 10px;
  border-radius: 20px;
  z-index: 999;
  background: var(--color-white);
  border: 2px solid var(--color-pri-red);
}

.reschedule-popup .react-datepicker {
  border: 0;
}

.reschedule-popup-date {
  border-radius: 18px 18px 0 0;
  min-height: 26px;
}

/* .reschedule-popup::before {
   content: '';
   border-top: 10px solid transparent;
   border-right: 0;
   border-bottom: 10px solid transparent;
   border-left: 25px solid var(--color-grey);
   position: absolute;
   right: 0;
   translate: 100% 0;
} */

.candidate_interview-list .custom-modal-overlay {
  background-color: transparent;
}

/*react datetime picker css*/
.candidate_interview-DatePicker .react-datepicker {
  border: unset;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 5px 8px;
}

.react-datepicker__day {
  border-radius: 50%;
  background-color: #eaf3ff;
  color: #006bff;
  font-weight: 600;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: #d9e1ec;
}

.react-datepicker__day--disabled {
  background-color: transparent;
  color: #ccc;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  border-radius: 50%;
  background-color: #006bff;
  color: #fff;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__current-month {
  text-align: start;
  padding-left: 20px;
}

.react-datepicker__navigation--previous {
  left: unset;
  right: 50px;
}

.react-datepicker-time__input {
  padding: 5px;
}

.disabled-image {
  opacity: 0.5;
  /* Reduce opacity to indicate disabled state */
  cursor: not-allowed;
  /* Change cursor to indicate not-allowed interaction */
  pointer-events: none;
}

.text-secondary {
  display: block;
  font-size: 8px;
}

.highlightRow {
  border: 2px solid #002d72;
}

/*datapicker style by Namrata*/

.btn-outline-pink {
  border: var(--color-pri-red);
}

.Banner {
  width: 100%; /* Full width of the parent (navbar) */
  min-height: 30px; /* Fixed height to ensure consistent appearance */
  background-color: red;
  color: white;
  padding: 10px 20px; /* Adjust padding for space around text */
  display: flex;
  align-items: center; /* Vertically center text */
  justify-content: center; /* Horizontally center the text */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
  overflow: hidden;
  position: sticky;
  top: 80px;
}
.Banner span {
  text-decoration: underline;
  color: "blue";
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .interview-list {
    width: auto;
  }
  .Banner {
    text-wrap: wrap;
  }
}

.react-datepicker__time-container {
  ::-webkit-scrollbar {
    height: 4px;
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(5, 3, 68, 0.5);
  }
}

.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__navigation--previous {
  left: unset;
  right: 140px;
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 767px) {
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__time-container {
    float: none;
    width: 100%;
    border-top: 1px solid #aeaeae;
    /*margin-bottom: 15px;*/
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    margin: 0px;
    width: 100%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    display: flex;
    width: 300px;
    overflow: scroll;
    height: 45px !important;
    overflow-y: hidden;
  }

  .react-datepicker__time-container .react-datepicker__time {
    height: 45px;
  }
}
