@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500&display=swap");

:root {
  --color-pri-blue: #005eab;
  --color-pri-red: #f04da1;
  --color-bg: #deebf7;
  --color-black: #1a1a1a;
  --color-grey: #a6a6a6;
  --color-white: #fff;
  --color-light: #f1f1f1;
  --error: #e7195a;
  --success: #007f59;

  --border-width: 1px;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

/* Overflow Hidden comment by Namrata*/
body {
  font-family: "Noto Sans JP", sans-serif;
  min-height: 100vh;
  display: flex;
  /* overflow: hidden; */
}

p {
  margin: 0;
  padding: 0;
}

#root {
  flex-grow: 1;
  background-color: var(--color-bg);
  display: flex;
}

/* opacity set by Namrata */
.modal-backdrop.show {
  opacity: 0.1 !important;
}

.App {
  flex-grow: 1;
}

/* button  */
.btn-grey {
  background: var(--color-grey);
  color: var(--color-black);
}

.btn-grey:hover {
  border: 1px solid var(--color-grey);
}

.btn-blue,
.bg-blue {
  background: var(--color-pri-blue);
  color: var(--color-white);
}

.btn-red,
.bg-red {
  background: var(--color-pri-red);
  color: var(--color-white);
}

.btn-blue:hover {
  color: var(--color-white);
  background-color: var(--color-pri-blue);
  border-color: var(--color-pri-blue);
}

.btn-red:hover {
  color: var(--color-white);
  background-color: var(--color-pri-red);
  border-color: var(--color-pri-red);
}

.btn-outline-blue,
.btn-outline-pink {
  border: 1px solid var(--color-pri-blue) !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-outline-pink {
  border: 1px solid var(--color-pri-red) !important;
}

.btn-outline-blue::before,
.btn-outline-pink::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-color: var(--color-pri-blue);

  transition: transform 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

.btn-outline-pink::before {
  background-color: var(--color-pri-red);
}

.btn-outline-blue:hover,
.btn-outline-pink:hover {
  color: var(--color-white) !important;
}

.btn-outline-blue:hover::before,
.btn-outline-blue:focus::before,
.btn-outline-pink:hover::before,
.btn-outline-pink:focus::before {
  transform: scaleX(1);
}

/* border  */
.border-blue {
  border: var(--border-width) solid var(--color-pri-blue);
}
.border-pink {
  border: var(--border-width) solid var(--color-pri-red);
}

.border-grey {
  border: var(--border-width) solid var(--color-grey);
}

.border-light-gray {
  border: 1px solid #cdd2d5;
}
.border-r-light-gray {
  border-right: 1px solid #cdd2d5;
}
.border-b-light-gray {
  border-bottom: 1px solid #cdd2d5;
}

/* text  */
.text-blue {
  color: var(--color-pri-blue);
}

.text-red {
  color: var(--color-pri-red);
}

.text-grey {
  color: var(--color-grey);
}

.cursor-pointer {
  cursor: pointer;
}

.white-space-nowrap {
  white-space: nowrap;
}

/* Prevent word wrapping but allow spaces to wrap */
.wrap-spaces-no-word-wrap {
  white-space: pre-wrap; /* Wraps only at spaces */
  word-break: keep-all; /* Prevents word breaking */
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

input.error {
  border: 1px solid var(--error);
}

div.success {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--success);
  color: var(--success);
  border-radius: 4px;
  margin: 20px 0;
}

.fa-whatsapp:before {
  font-family: "Font Awesome 6 Brands";
  content: "\f232";
}

/* bootsap btn css changes */
.btn-primary {
  background-color: var(--color-pri-blue);
}

.btn-outline-primary {
  border-color: var(--color-pri-blue);
  color: var(--color-pri-blue);
}

.poniter-event-none {
  pointer-events: none;
}

.floating-control:has(.password) {
  border-radius: 0.25rem 0 0 0.25rem;
}

.toggle-password-icon {
  border-radius: 0 0.375rem 0.375rem 0;
}

.w-fit-content {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

/*floating lable css start ---------------------------------*/

.floating-control {
  position: relative;
  width: 100%;
  border-radius: 0.25rem;
  border: 1.5px solid var(--color-grey);
  /* min-height: 4rem; */
  /* margin-bottom: 1.5rem; */
}

.floating-label {
  position: absolute;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0.25rem;
  color: var(--color-gray);
  background: var(--color-light);
  pointer-events: none;
  transition: all 0.3s ease;
}

.floating-input {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  resize: none;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.75rem 1.25rem;
  /* border-radius: 0.25rem;
  border: 1.5px solid var(--color-grey); */
  border: transparent;
  color: var(--color-black);
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.floating-input::placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.floating-input:focus {
  outline: none;
  border: 2px solid var(--color-pri-blue);
}

.floating-control:has(.floating-input:focus) {
  border: 2px solid var(--color-pri-blue);
}

.floating-input:focus ~ .floating-label {
  top: 0;
  left: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-pri-blue);
  transition: all 0.2s ease-in-out;
}

.floating-input:not(:placeholder-shown).floating-input:not(:focus)
  ~ .floating-label {
  top: 0;
  left: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

/*floating lable css end ---------------------------------*/

/*Candidate Layout Css */

/* Overflow auto comment by Namrata*/
.CandidateBody,
.EmployerBody {
  /* height: 100vh; */
  /* overflow-y: auto; */
  padding-bottom: 30px;
}

/* //Bootsrap modal  */
@media (max-width: 575.98px) {
  .modal-dialog {
    width: 93vw;
    margin-inline: auto;
  }
}
